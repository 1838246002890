import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FiPrinter } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { actions as actionsSiteWrapper } from 'app/containers/SiteWrapper/slice';
import { PartOrder } from 'app/models/PartOrder';

// import styled from 'styled-components';
interface Props {
  orders?: PartOrder[];
  text: string;
  getInfo: (orders: number[]) => void;
  highPriority: boolean;
}

const CuttingModalToPrintLine = ({
  orders,
  text,
  getInfo,
  highPriority,
}: Props) => {
  const dispatch = useDispatch();

  if (orders && orders.length > 0)
    return (
      <div className="flex justify-center items-center mb-1">
        <div className="w-96 text-left flex">
          <div>
            {orders.length} commande
            {orders.length > 1 && 's'} {text}
          </div>
          <div
            data-tip={orders
              .sort((a, b) => a.id - b.id)
              .map(e => e.identification ?? 'inconnu')
              .join(' - ')}
          >
            <FaInfoCircle className="w-4 h-4 ml-2 mt-1" />
          </div>
          <ReactTooltip place="bottom" type="dark" />
        </div>
        <div className="w-40 flex justify-end">
          <button
            onClick={() => {
              if (highPriority)
                dispatch(actionsSiteWrapper.setNumberOfCuttings(0));
              getInfo(orders.map(e => e.id));
            }}
            className={`flex items-center border border-gray-700 py-1 px-2 w-28 ${
              highPriority ? 'bg-main text-white' : ''
            }`}
          >
            <FiPrinter className="h-4 w-4 mr-2" />
            Imprimer
          </button>
        </div>
      </div>
    );

  return null;
};

export default CuttingModalToPrintLine;
