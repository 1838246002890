import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';
import { selectOrderAction } from 'app/containers/TourMap/slice';
import DateDelay from 'app/components/DateDelay';
import { FaCubes, FaHammer } from 'react-icons/fa';
import { FiMessageSquare, FiSmartphone } from 'react-icons/fi';
import { deleteDeliveryAction } from 'app/containers/ToursList/slice';
import { selectSearchFieldValue } from 'app/containers/SearchField/selectors';
import { differenceInMinutes, format, formatISO, parseISO } from 'date-fns';
import {
  MAX_DIFFERENCE_DELIVERY_TIME_ALLOWED,
  STATUS_FINAL_PROCESSING,
  STATUS_PARTIAL_PROCESSING,
} from 'app/pages/ToursPage/constants';
import { refreshPartOrder } from 'app/containers/SiteWrapper/slice';
import { Delivery } from 'app/models/Delivery';
import InfoLocation from 'app/pages/ToursPage/InfoLocation';
import { MdCallSplit, RiAlarmWarningFill } from 'react-icons/all';
import WaypointTourItem from 'app/containers/ToursList/WaypointTourItem';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { requestGet } from 'utils/requests';
import {
  createPartOrder,
  PartOrder,
  PartOrderJson,
} from 'app/models/PartOrder';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllColors } from 'store/slices/colorSlice';
import {
  selectDeliveryById,
  selectLoadingCarrierIds,
} from 'store/slices/deliverySlice';
import { AppRootState } from 'index';
import { selectPartOrderById } from 'store/slices/partOrderSlice';
import {
  selectCurrentPartOrder,
  setActiveSearch,
  setCurrentPartOrderId,
} from 'store/slices/activeContentSlice';
import {
  useCarrierReservationMutation,
  useConfirmDeliveryMutation,
  useNotifyDeliveryCustomerMutation,
} from 'services/kewloxApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircleSpinner } from 'react-spinners-kit';
import { Carrier } from 'types';

interface Props {
  deliveryId: number;
  position: number;
  tourDate: string;
  history: boolean;
  tourId: number;
}

const initialState = {
  mouseX: null,
  mouseY: null,
};

const DeliveryItem = ({
  deliveryId,
  position,
  tourDate,
  history,
  tourId,
}: Props) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const searchOrder = useSelector(selectSearchFieldValue);
  const activePartOrderDetails = useSelector(selectCurrentPartOrder());
  const destinations = useSelector(selectAllDestinations);
  const carriers = useSelector(selectAllCarriers);
  const colors = useSelector(selectAllColors);

  // const loadingCarrierIds = useSelector(
  //   state => state.delivery.loadingCarrierIds,
  // );

  const loadingCarrierIds: number[] = useSelector(selectLoadingCarrierIds());

  const [notifyDeliveryCustomerMutation] = useNotifyDeliveryCustomerMutation();
  const [confirmDeliveryMutation] = useConfirmDeliveryMutation();
  const [carrierReservation] = useCarrierReservationMutation();

  const delivery = useSelector((state: AppRootState) =>
    selectDeliveryById(state, deliveryId),
  );

  const partOrder = useSelector((state: AppRootState) =>
    selectPartOrderById(state, delivery?.partOrder),
  );

  const navigate = useNavigate();

  const [mousePos, setMousePos] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  if (!!delivery?.waypoint) {
    return (
      <WaypointTourItem
        delivery={delivery}
        index={position}
        tourDate={tourDate}
      />
    );
  }

  if (!partOrder || !delivery) return null;

  if (!partOrder.order.infos)
    return <div>Order infos don't exist for {partOrder.order.id}</div>;

  const { invoiceDate, isoCode, messages, province, priority, lastname } =
    partOrder.order.infos;

  const onMouveEnter = () => {
    if (partOrder.id && activePartOrderDetails === undefined)
      dispatch(selectOrderAction(partOrder));
  };

  const canNotify =
    partOrder.isKewlox && !delivery.notification && !delivery.confirmed;

  const handleOnContextMenuClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    if (showMenu) {
      handleClose();
      setShowMenu(false);
    } else {
      setMousePos({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setShowMenu(true);
    }
  };

  const handleClose = () => {
    setMousePos(initialState);
  };

  const showWeight =
    partOrder.statusId !== STATUS_PARTIAL_PROCESSING &&
    partOrder.statusId !== STATUS_FINAL_PROCESSING;

  function isInDeliveryRange(deliveryTime: string, customerTime: string) {
    return (
      differenceInMinutes(parseISO(deliveryTime), parseISO(customerTime)) <=
      MAX_DIFFERENCE_DELIVERY_TIME_ALLOWED
    );
  }

  function getTimeDeliveryStatus(orderTour: Delivery) {
    if (orderTour.deliveryTime) {
      if (orderTour.customerTime) {
        if (isInDeliveryRange(orderTour.deliveryTime, orderTour.customerTime)) {
          return 'bg-green';
        } else return 'bg-red';
      } else {
        return 'bg-main';
      }
    }
  }

  function canMakeCarrierReservation(carrier: Carrier | undefined) {
    if (!carrier) return false;

    // The name must match exactly the Kewapp carrier name
    const authorizedCarriers = [
      'BRENGER',
      'Kuehne+Nagel',
      'ZIEGLER',
      'GARSOU-ANGENOT',
    ];

    return authorizedCarriers.includes(carrier.name);
  }

  const isLoading = loadingCarrierIds.includes(deliveryId);

  return (
    <Draggable
      key={partOrder.id}
      draggableId={`${partOrder.id}`}
      index={position}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onMouseDownCapture={e => {
            if (e.button === 2) handleClose();
          }}
        >
          <div
            onContextMenu={handleOnContextMenuClick}
            onMouseEnter={onMouveEnter}
            onDoubleClick={() => {
              dispatch(selectOrderAction(partOrder!));
              dispatch(setCurrentPartOrderId(partOrder.id));
            }}
          >
            <OrderCardStyle
              notified={delivery.notification}
              confirmed={delivery.confirmed}
              searchOrder={parseInt(searchOrder) === partOrder!.order.id}
              pickingListPrinted={!!partOrder!.pickingListPrinted}
              error={delivery.carrierError}
            >
              {isLoading && (
                <div className="flex justify-center py-1">
                  <CircleSpinner size={20} color="#4a5568" />
                </div>
              )}

              {/*{orderTour.order!.cutting && (*/}
              {/*  <div className="relative">*/}
              {/*    <TriangleStyled*/}
              {/*      alreadyCut={orderTour.order!.cuttingBatchPrint !== undefined}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}
              {!isLoading && invoiceDate && (
                <div className="text-xs italic flex justify-center">
                  <div className="px-2">
                    <DateDelay
                      date={invoiceDate}
                      dateShipping={
                        partOrder!.direct
                          ? formatISO(new Date())
                          : tourDate < formatISO(new Date())
                          ? formatISO(new Date())
                          : tourDate
                      }
                      priority={priority}
                    />
                  </div>
                </div>
              )}
              <div
                className={`text-sm font-semibold flex items-center justify-center ${
                  partOrder!.pickingListPrinted ? 'text-gray-800' : 'text-white'
                }`}
              >
                {partOrder!.identification}
                {partOrder!.assembly && <FaHammer className="h4 w-4 mx-1" />}
                {!partOrder!.packageDelivery &&
                  partOrder!.index > 1 &&
                  (partOrder!.total > 1 ||
                    partOrder!.statusId === STATUS_PARTIAL_PROCESSING ||
                    partOrder!.statusId === STATUS_FINAL_PROCESSING) && (
                    <MdCallSplit
                      className="h4 w-4 mx-1"
                      data-tip={`Sous-commande`}
                      data-for="infos"
                    />
                  )}
                {partOrder!.packageDelivery && (
                  <FaCubes
                    className="h4 w-4 mx-1"
                    data-tip={`Livraison partielle de colis`}
                    data-for="infos"
                  />
                )}
                {partOrder!.outOfStockProducts && (
                  <RiAlarmWarningFill
                    className="h-5 w-5 mx-1 bg-red text-white"
                    data-tip={'Rupture de stock'}
                    data-for="infos"
                  />
                )}
                {/*{orderTour.order!.statusId === STATUS_PARTIAL_PROCESSING && (*/}
                {/*  <FaCube*/}
                {/*    className="h4 w-4 mx-1"*/}
                {/*    data-tip={`Livraison partielle, il restera après : ${orderTour.order!.orderInfo.remainsDelivery}`}*/}
                {/*    data-for="infos"*/}
                {/*  />*/}
                {/*)}*/}
                {/*{orderTour.order!.statusId === STATUS_FINAL_PROCESSING && (*/}
                {/*  <FaCubes*/}
                {/*    className="h4 w-4 mx-1"*/}
                {/*    data-tip={`Livraison finale : ${orderTour.order!.orderInfo.remainsDelivery}`}*/}
                {/*    data-for="infos"*/}
                {/*  />*/}
                {/*)}*/}
              </div>
              <div className="flex justify-around">
                {/*<div className="text-xs">{packages}C</div>*/}

                {/*{weight !== undefined && (*/}
                {/*  <div className="text-xs ml-2">*/}
                {/*    {showWeight ? weight.toFixed(0) : '-- '}kg*/}
                {/*  </div>*/}
                {/*)}*/}

                {lastname && (
                  <div className="text-xs ml-0 capitalize">
                    {lastname.toLowerCase()}
                  </div>
                )}
              </div>
              <div className="flex justify-around items-center">
                {partOrder!.isHomeTour && (
                  <div className="text-xs font-semibold ">
                    <InfoLocation
                      province={province}
                      isoCode={isoCode}
                      size={16}
                    />
                  </div>
                )}
                {messages && messages > 0 ? (
                  <div className="flex items-center">
                    <div className="text-xs">{messages}</div>
                    <FiMessageSquare className="h-4 w-4 text-orange" />
                  </div>
                ) : null}
              </div>
              <Menu
                keepMounted={false}
                open={mousePos.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  mousePos.mouseY !== null && mousePos.mouseX !== null
                    ? { top: mousePos.mouseY, left: mousePos.mouseX }
                    : undefined
                }
                // transitionDuration={0}
              >
                <MenuItem
                  onClick={() => {
                    dispatch(selectOrderAction(partOrder!));
                    dispatch(setCurrentPartOrderId(partOrder.id));
                    if (history) {
                      dispatch(
                        setActiveSearch({
                          tourIds: [tourId],
                          partOrderIds: [partOrder.id],
                        }),
                      );
                      navigate('/');
                    }
                    handleClose();
                  }}
                >
                  Détails
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a
                    href={`${
                      import.meta.env.VITE_KEWNET_URL
                    }/adminkew/goToOrder.php?id=${partOrder!.order.id}`}
                    target="_blank"
                  >
                    Kewnet
                  </a>
                </MenuItem>
                {canNotify && (
                  <MenuItem
                    onClick={() => {
                      notifyDeliveryCustomerMutation(delivery.id)
                        .unwrap()
                        .catch(error => {
                          toast.error(
                            `Erreur lors de l'envoi de la notification pour la commande ${
                              partOrder!.order.id
                            }`,
                          );
                        });
                      handleClose();
                    }}
                  >
                    <div className="flex items-center">
                      Avertir <FiSmartphone className="h-4 w-4 ml-2" />
                    </div>
                  </MenuItem>
                )}
                {canMakeCarrierReservation(partOrder?.carrier) &&
                  !delivery.confirmed && (
                    <MenuItem
                      onClick={() => {
                        carrierReservation(delivery.id)
                          .unwrap()
                          .catch(error => {
                            toast.error(
                              `Erreur lors de la réservation du transporteur pour la commande ${
                                partOrder!.order.id
                              } chez ${partOrder!.carrier?.name}`,
                            );
                          });
                        handleClose();
                      }}
                    >
                      Réservation du transporteur
                    </MenuItem>
                  )}
                {(partOrder?.isKewlox ||
                  canMakeCarrierReservation(partOrder?.carrier)) &&
                  !delivery.confirmed && (
                    <MenuItem
                      onClick={() => {
                        confirmDeliveryMutation(delivery.id)
                          .unwrap()
                          .catch(error => {
                            toast.error(
                              `Erreur lors de la confirmation de la commande ${
                                partOrder!.order.id
                              }`,
                            );
                          });
                        handleClose();
                      }}
                    >
                      Confirmer
                    </MenuItem>
                  )}

                {partOrder!.pickingListPrinted && (
                  <MenuItem
                    onClick={() => {
                      const url = `${PARTORDER_URL}${
                        partOrder!.id
                      }/picking_list_printed/?cancel=true`;
                      requestGet(url).then((partOrdersJson: PartOrderJson) => {
                        const newPartOrder = createPartOrder(
                          partOrdersJson,
                          destinations,
                          carriers,
                          colors,
                        );
                        dispatch(refreshPartOrder(newPartOrder));
                        handleClose();
                      });
                    }}
                  >
                    Non imprimée
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    dispatch(deleteDeliveryAction(delivery));
                    handleClose();
                  }}
                >
                  Retirer
                </MenuItem>
              </Menu>
            </OrderCardStyle>
          </div>
          {delivery.deliveryTime && (
            <div className="w-full  flex justify-center items-center -mt-2">
              <div
                className={`${getTimeDeliveryStatus(
                  delivery,
                )} text-white border text-xs px-1`}
              >
                {format(parseISO(delivery.deliveryTime), 'HH:mm')}
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DeliveryItem;

const OrderCardStyle = styled.div<{
  notified?: boolean;
  confirmed?: boolean;
  searchOrder: boolean;
  pickingListPrinted: boolean;
  error?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin: 0.25rem;
  border-width: 2px;
  border-radius: 0.25rem;
  line-height: 1.25;
  color: #4a5568;
  border-color: #85a5c1;
  background-color: #ffffff;
  min-width: 4.2rem;
  user-select: none;

  ${p =>
    !p.pickingListPrinted &&
    css`
      background-color: #e280c1;
      color: #fff;
    `}

  ${p =>
    p.searchOrder &&
    css`
      background-color: #f1c932;
    `}


    ${p =>
    p.confirmed &&
    css`
      border-color: #14b60c;
      border-width: 3px;
    `}

    ${p =>
    p.notified &&
    !p.confirmed &&
    css`
      border-color: #d98028;
      border-width: 3px;
    `}
    
    ${p =>
    p.error &&
    !p.confirmed &&
    css`
      border-color: #ff2828;
      border-width: 4px;
    `}
`;

const MenuOptionStyled = styled.li`
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;

  &:hover {
    background-color: #718096;
    color: #ffffff;
  }
`;

const TriangleStyled = styled.div<{ alreadyCut: boolean }>`
  position: absolute;

  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #0e73d2;
  ${p =>
    p.alreadyCut &&
    css`
      border-bottom: 5px solid #0ed23f;
    `}

  top: 0;
  left: 0;

  margin-left: -2px;
  transform: rotate(-45deg) scale(1.5);
`;
